
























































































































































































































































import { Component, Mixins } from 'vue-property-decorator'
import Methods from '@/components/methods'

@Component
export default class Agreement extends Mixins(Methods) {
    created(): void {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
    }
}
